@import "node_modules/bootstrap/scss/bootstrap";
@import "_colors";
@import "_mixins";
@import "_carousel";
@import "_toggler-animation";


body {
	font-family: 'Montserrat', sans-serif;
	background-color: $background-color;
}

.heading-wrap .heading-main {
	color: $gold;
	font-family: "Playfair Display";
	background-color: rgba(0,50,40,0.8);
	padding: 10px 20px;
	margin-bottom: 50px;
	font-size: 56px;
}

h2 {
	color: $dark-green;
	font-family: "Playfair Display";
	text-transform: uppercase;
	font-size: 50px;
	letter-spacing: 0.3px;
	line-height: 75px;
	margin-top:60px;
}

h3{
	font-size: 16px;
	color: $dark-green;
}

h4 {
	font-size: 14px;
	color: $dark-green;
}

p,
li {
	font-size: 16px;
	color: $brown;
}

.block {
	display: block;
}


//Scroll indicator

.scroll-indicator {
  position: fixed;
  bottom: 0;
  z-index: 5;
  width: 100%;
  background-color: #f1f1f1;
}

/* The progress container (grey background) */
.progress-container {
  width: 100%;
  height: 8px;
  background: #ccc;
}

/* The progress bar (scroll indicator) */
.progress-bar {
  height: 8px;
  background: $dark-green;
  width: 0%;
}

// NAVBAR

.logo {
	width: 80px;
	height: 55px;
	display: inline-block;
	background-image: url('../.././img/svg/akant-logo.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.logo-text {
	color: $dark-green;
	font-family: "Playfair Display";
	font-size: 40px;
}

.navbar {
	background: rgba(255, 255, 255, 0.7);

	.navbar-toggler {
		outline: none;
	}

	.nav-link {
		color: $dark-green;
		padding: 10px;
		font-size: 14px;
	}

	.nav-item {
		margin: 20px;
	}

	.ask-button {
		border: 1px solid $gold;
		border-radius: 24px;
		padding: 0px 50px;
		transition: all 0.2s linear;
		margin: 20px 0 20px 20px;

		&:hover {
			border: 1px solid $dark-green;
			background-color: $dark-green;

			.nav-link {
				color: $gold;
			}
		}
	}

	.nav-item.lang {
		display: flex;
		align-items: center;

		.nav-link {
			line-height: 20px;
		}

		img {
			height: 20px;
			width: 20px;
		}
	}
}

// Hero image

.hero-image {
	@include flexbox();
	@include align-items(flex-end);
	position: relative;
	background-image: url(../../img/hero-image.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding: 0;

	.overlay {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		bottom: -5px;
		background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(250,250,250,0.5) 50%, rgba(250,250,250,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(250,250,250,0.5) 50%,rgba(250,250,250,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(250,250,250,0.5) 50%,rgba(250,250,250,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#fafafa',GradientType=0 ); /* IE6-9 */
	}
}

.heading-wrap {
	@include flexbox();
	@include flex-direction(column);
	@include align-items(center);
	z-index: 1;
	padding-top: 450px;

	.heading-expand-text {
		width: 90%;
		text-align: center;
	}

	.expandable-text {
		&.hidden {
			display: none;
		}
	}

	.read-more {
		font-size: 16px;
	}
}

// Four column section

.four-column-section {
	margin-bottom: 30px;

	.column-item {
		position: relative;
		border-right: 1px solid $gold;
		transition: all 0.15s ease-out;

		&.first {
			border-left: 1px solid $gold;
		}
	}

	.column-fixed-content {
		text-align: center;
	}

	.column-expand-text {
		text-align: center;

		p {
			margin-bottom: 1em;
		}
	}

	.column-img {
		margin: 20px 0;
		height: 100px;
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
	}

	.column-text {
		color: $dark-green;
		font-size: 22px;
	}
}


//Gallery stylesheet
.gallery-section {
	.actions {
		
	}
}


.amenities-container {
	@include flexbox();
}

.amenities-content {
	@include flexbox();
}

.amenities-heading {
	p {
		color: $brown;
		max-width: 600px;
	}
}

.amenities-holder {
	@include flexbox();
	@include flex-wrap(wrap);
	@include justify-content(flex-start);
	padding: 0;

	.amenities-item {
		box-sizing: border-box;
		width: 30%;
		margin: 0 20px 0 0;
		padding: 20px 0;
		@include flexbox();

		.amenities-img {
			padding: 0 20px 0 0;

			img {
				height: 44px;
				width: 44px;
			}
		}

		.amenities-text {

			h4 {
				display: block;
			}
			p {
				display: block;
				font-size: 11px;
				margin: 0;
			}
		}
	}
}

.amenities-side {
	p {
		color: $brown;
	}

	.side-rules-list {
    	padding-left: 15px;
		list-style:none;
		color: $dark-green;
		margin: 0;
		border-left: 1px solid $dark-green;
	}

	.side-rule {
		margin: 0 0 20px 0;
		padding: 0 0 0 10px;

		&:before {
			content: '';
			display: inline-block;
			background: url("../../img/svg/ic_forbidden.svg") no-repeat 0 0;
			width: 15px;
		    height: 15px;
		    position: relative;
		    top: 5px;
			left: -10px;
		}
	}

	.side-check-in,
	.side-check-out {
		margin: 0 0 20px 0;
		padding: 0 0 0 10px;

		&:before {
			content: '';
			display: inline-block;
			background: url("../../img/svg/ic_checkin.svg") no-repeat 0 0;
			width: 15px;
			height: 15px;
			position: relative;
			top: 5px;
			left: -10px;
		}
	}
}

//Calculator section

.calculator-input {
	@include flexbox();
	@include justify-content(space-between);
	@include flex-wrap(nowrap);
	position: relative;

	.equal,
	.plus {
		font-size: 44px;
		margin-right: 15px;
		color: $dark-green;
		font-weight: 300;
	}

	.input-wrap {
		margin-right: 15px;

		&.disabled {
			opacity: 0.6;
			pointer-events: none;
		}
	}

	.input-field {
		@include flexbox();
		padding: 20px;
		border: 1px solid $gold;
		background-color: $background-color;
		text-align: center;

		&#guests {
			width: 130px;
		}
	}

	.input-label {
		color: $brown;
		font-size: 16px;
		position: absolute;
		margin: 0;
		line-height: 1;
		margin: 25px;
		transition: transform 2s 0.2s ease-in-out;
		transform:scale3d(1,1,1);

		&.active {
			transform:scale3d(0.3,0.3,1);
			line-height: 1;
			margin: 10px;

		}
	}

	.price {

		.price-total {
			text-align: center;
			font-size: 24px;
		}

		.price-per-person span {
			white-space: nowrap;
			font-size: 14px;
		}
	}
}

// Contacts section
.contacts-container {
	@include flexbox();
	@include flex-wrap(wrap);
}

.contacts-input {
	padding: 0;
}

.contacts-info {
	@include flexbox();
	@include justify-content(space-between);
	margin: 0 0 30px 0;

	.contacts-location,
	.contacts-mail {
		@include flexbox();
		width: 48%;

		.location-img,
		.mail-img {
			padding: 0 15px 15px 0;
		}

		h3 {
			margin: 0;
			color: $brown;
			line-height: 1.4;
		}
	}
}

.contacts-form  {
	@include flexbox();
	@include justify-content(space-between);
	@include flex-wrap(wrap);
	position: relative;
	transition: all 0.3s ease-out;


	.input-wrap {
		width: 48%;
		margin: 0 0 10px 0;

		&.double {
			width: 100%;
		}

		&.disabled {
			opacity: 0.6;
			pointer-events: none;
		}
	}

	.error {
		color: red;
		margin: 5px 0 0;
	}

	.input-field {
		@include flexbox();
		width: 100%;
		padding: 20px;
		border: 1px solid $gold;
		background-color: $background-color;

		&.double {
			width: 100%;
			height: 8em;
		}
	}

	.input-label {
		color: $brown;
		font-size: 16px;
		position: absolute;
		margin: 0;
		line-height: 1;
		margin: 25px;
		transition: all 0.2s ease-in-out;

		&.active {
			font-size: 10px;
			line-height: 1;
			margin: 10px;
		}
	}

	.send-button {
		background-color: $dark-green;
		color: $gold;
		padding: 20px;
		width: 100%;
		margin: 15px 0;
	}
}


.contacts-form-wrap {
	position: relative;
	
	.success-message {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		transition: all 0.6s ease-in;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		h3 {
			font-size: 22px;
		}

		img {
			width: 33%;
		}
	}

	&.disabled {
		pointer-events: none;
		.contacts-form {
			opacity: 0.1;
		}
		
		.success-message {
			opacity: 1;
			z-index: 2;
		}

	}
}


.contacts-map {
	padding-bottom: 15px;
	#map {
		border: 1px solid $gold;
	  height: 100%;
	  width: 100%;
	}
}

.footer {
	@include flexbox();
	@include align-items(center);
	@include justify-content(flex-end);
	color: $brown;
	font-size: 16px;
	line-height: 24px;
	border-top: 1px solid $dark-green;
	padding: 15px 0 20px;

	.logo-wrap {
		margin-right: auto;
	}

	span {
		margin: 0 10px;
	}
}


@media only screen and (max-width: 992px) {

	h2 {
		margin-top: 30px;
		font-size: 42px;
		letter-spacing: 0.3px;
		line-height: 50px;
	}
	
	h3{
		font-size: 16px;
	}
	
	h4 {
		font-size: 14px;
	}
	
	p,
	li {
		font-size: 16px;
	}

	// Four column section
	.four-column-section {
		.column-item {
			border: none;

			.column-img {
				margin: 20px 0;
			}

			&.first {
				border-left: none;
				border-right: 1px solid $gold;
				border-bottom: 1px solid $gold;
			}

			&.last {
				border-left: 1px solid $gold;
				border-top: 1px solid $gold;
				margin-left: -1px;
				margin-top: -1px;
			}
		}
	}

	//Amenities
	.amenities-content {
		@include flex-wrap(wrap);

		.amenities-holder {
			margin-bottom: 30px;
		}

		.amenities-item {
			width: 50%;
			margin: 0;
		}
	}

	//Calculator
	.calculator-input {
		@include flex-wrap(wrap);
		@include justify-content(center);

		.input-wrap {
			@include flexbox();
			@include justify-content(center);
			width: 100%;
			margin: 15px 0;

			.input-field {
				width: 100%;

				&#guests {
					width: 100%;
				}
			}

			.input-label {
				margin: 28px 0;

				&.active {
					margin: 10px 0;
				}
			}
		}

		.equal,
		.plus {
			margin: 15px 0;
		}

		.price {
			width: 100%;
			text-align: center;
		}
	}

	.contacts-map {
		padding: 0;
		height: 410px;
		width: 100%;
		margin-bottom: 15px;
	}

// NAVBAR
.navbar {
	.navbar-nav {
		align-items: center;
	}

	.nav-link {
		padding: 10px;
		font-weight: bold;
	}

	.nav-item {
		margin: 5px;
	}

	.ask-button {
		border: none;
		padding: 0px;
		transition: all 0.2s linear;
	}
}


}

@media only screen and (max-width: 768px) {

	h2 {
		margin-top: 30px;
		font-size: 36px;
		letter-spacing: 0.3px;
		line-height: 50px;
	}
	
	h3{
		font-size: 16px;
	}
	
	h4 {
		font-size: 14px;
	}
	
	p,
	li {
		font-size: 16px;
	}

	p {
		font-size: 14px;
	}

	.heading-wrap {
		padding-top: 300px;
		.heading-main {
			text-align: center;
			font-size: 40px;
			margin-bottom: 30px;
		}
	}
	//Contacts
	.contacts-info {
		@include flex-wrap(wrap);
		.contacts-location,
		.contacts-mail {
			width: 100%;
			margin: 10px 0;
		}
	}

	.footer {
		padding: 20px;
		@include flex-direction(column);

		.logo-wrap {
			margin: 10px 0;
		}
	}
}

@media only screen and (max-width: 576px) {

	// Four column section
	.four-column-section {
		.column-item {
			border: none;

			&.first,
			&.last  {
				border: none;
			}
		}
	}

	//Amenities
	.amenities-content {
		@include flex-wrap(wrap);

		.amenities-holder {
			margin-bottom: 30px;
		}

		.amenities-item {
			width: 100%;
			margin: 0;
		}
	}

	.contacts-form  {
		@include flexbox();
		@include justify-content(space-between);
		@include flex-wrap(wrap);
		position: relative;

		.input-wrap {
			width: 100%;
			margin: 0 0 15px 0;

		}
	}
}
