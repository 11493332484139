@import "_colors";
@import "_mixins";

.prev-link {
    position: absolute;
    height: 100%;
    width: 50%;
    left: 0;
    top: 0;
    z-index: 1;
}

.next-link {
    position: absolute;
    height: 100%;
    width: 50%;
    right: 0;
    top: 0;
    z-index: 1;
}


.card-carousel {
    user-select: none;
    position: relative;
}

.progressbar {
    display: block;
    width: 100%;
    height: 5px;
    position: absolute;
    background-color: rgba(221, 221, 221, 0.25);
    z-index: 1;
}

.progressbar > div {
    background-color: rgba(255, 255, 255, 0.52);
    height: 100%;
}

.thumbnails {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
}

.thumbnail-image {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    max-width: 20%;
}

.thumbnail-image > img {
    width: 100%;
    height: auto;
    transition: all 250ms;
    opacity: 0.6;
}

.thumbnail-image:hover > img,
.thumbnail-image.active > img {
    opacity: 1;
}

.card-img {
    position: relative;
}

.card-img > img {
    width: 100%;
    display: block;
    margin: 0 auto;
}

.actions {
    font-size: 1.5em;
    height: 40px;
    position: absolute;
    top: 50%;
    margin-top: -20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #585858;

    .next,
    .prev {
        fill: $gold;
        text-align: center;

        svg {
            width: 50px;
            height: 50px;
            background-color: $dark-green;
            opacity: 0.6;
        }
    }
}

.actions > span {
    cursor: pointer;
    transition: all 250ms;
    width: 50px;
    height: 50px;
}

.actions > span:hover {
    color: #eee;
}

.card {
    border: none;
    background-color: transparent;
}

.room-container {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;

    .room {
        padding: 20px 0;
        transition: all 0.3s ease-in-out; 
        opacity: 0.4;
        @include flex(1);
        text-align: center;

        &.active {
            opacity: 1;
            border-bottom: 1px solid $dark-green;
        }

        &:hover {
            opacity: 1;
            background-color: $wheat;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .room-container {    
        .room {
            padding: 10px 0;
            font-size: 14px;
        }
    }
}


@media only screen and (max-width: 768px) {
    .thumbnails {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-x: auto;
        width: 100%;
    }
    
    .thumbnail-image {
        min-width: 225px;
    }

    .room-container {    
        margin: 0;
        .room {
            display: none;
            font-size: 16px;


            &.active {
                display: inline-block;
                opacity: 1;
                border: none;
                pointer-events: none;
            }
        }
    }

    .actions {

        .next {
            text-align: right;
        }

        .prev {
            text-align: left;
        }
    
        .next,
        .prev {
            svg {
                width: 32px;
                height: 32px;
            }
        }
    }
}